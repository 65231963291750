import React from 'react'
import './style.css'
import { SignupContainer } from './style.js'
import LeftSideWrapper from './LeftSide.js'
import RightSideWrapper from './RightSide.js'
import * as S from '../../Pages/Landing/style'
import { ReactComponent as LogoIcon } from '../../../assets/Icons/Landing/logo.svg'
import { StartedButton } from '../../Pages/Landing/Components/StaticComponents'
import Footer from '../../Pages/Landing/Footer'

export default function SignupWrapper(props) {
    return (
        <>
            <S.GlobalStyle />
            <S.Header>
                <a href="/">
                    <LogoIcon />
                </a>
                <div className="links">
                    {/* <a href="https://www.springworks.in/about-us/">ABOUT US</a> */}
                    <a href="/signin/">LOGIN</a>
                    {/* <a href="https://springrecruit.com/blog/" target="=_blank">
                        BLOG
                    </a> */}
                    <StartedButton top />
                </div>
            </S.Header>

            <SignupContainer>
                <div className="block-wrapper">
                    <LeftSideWrapper>{props.children}</LeftSideWrapper>
                    <RightSideWrapper />
                    <div className="mobile-view-half-bottom">
                        <div className="block-element block-e-25 block-e-23-block-content">
                            <p>Total control over job openings - Create, promote, manage and lot more!</p>
                            <div className="block-icon">
                                <img src={require('./images/total-control.png')} default />
                            </div>
                        </div>

                        <div className="realtime-collabration">
                            <div className="block-element block-e-22 block-e-22-block-content">
                                <p>Realtime Dashboard</p>
                                <div className="block-icon">
                                    <img src={require('./images/realtime-dashbaord.png')} default />
                                </div>
                            </div>
                            <div className="block-element block-e-26 block-e-22-block-content">
                                <p>Designed for collaboration</p>
                                <div className="block-icon">
                                    <img src={require('./images/collabortation.png')} default />
                                </div>
                            </div>
                        </div>

                        <div className="block-element block-e-23 block-e-23-block-content">
                            <p>Simple, elegant & advanced ATS for startups </p>
                            <div className="block-icon">
                                <img src={require('./images/ats-assembly.png')} default />
                            </div>
                        </div>

                        <div className="realtime-collabration">
                            <div className="block-element block-e-19 block-e-19-block-content">
                                <p>Quick sourcing</p>
                                <div className="block-icon">
                                    <img src={require('./images/sourcing.svg')} default />
                                </div>
                            </div>
                            <div className="block-element block-e-27 block-e-18-block-content">
                                <p>One stop for candidates from all sources</p>
                                <div className="block-icon">
                                    <img src={require('./images/candiate-src.png')} default />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SignupContainer>
            <Footer />
        </>
    )
}
