import React, { useState } from 'react'
import { ContentWrapper, FormWrapper, ToCWrapper } from './style'
import { Button, Form, Icon, Input, Select, Radio } from 'antd'
import EmailIcon from '../../../assets/Icons/Auth/Email'
import { ReactComponent as PhoneLogo } from '../../../assets/Icons/Auth/Phone.svg'
import CompanyNameIcon from '../../../assets/Icons/Auth/Comapny Name'
import PasswordIcon from '../../../assets/Icons/Auth/Password'
import URLIcon from '../../../assets/Icons/Auth/URL'
import { Link } from 'react-router-dom'
import { areDomainSame } from './utils'
import { countryCodes } from '../../Helpers/countryCode'
import { phoneValidator, emailValidator } from '../../Helpers/utils'
const { Group: RadioGroup } = Radio

export const SIGNUPS = props => {
    const { getFieldDecorator, signup, slug, history, getFieldsValue, loading, form } = props
    const [passwordType, setPasswordType] = useState(true)
    const validateWebsiteUrl = (rule, value, callback) => {
        if (!!slug) {
            callback()
            return
        }
        let { email } = getFieldsValue(['email'])
        if (email && email.length && areDomainSame(email, value)) {
            callback('The email should be from the company website')
        }
        callback()
    }

    const validateEmail = (rule, value, callback) => {
        if (!!slug) {
            callback()
            return
        }
        let { websiteUrl } = getFieldsValue(['websiteUrl'])
        if (websiteUrl && websiteUrl.length && areDomainSame(value, websiteUrl)) {
            callback('The email should be from the company website')
        }
        callback()
    }

    const handleEnterPress = e => {
        e.preventDefault()
        signup()
    }
    const prefixSelector = getFieldDecorator('countryCode', {
        rules: [
            {
                validator: phoneValidator(
                    field => form.getFieldValue(field),
                    (field, value, errors) => {
                        form.setFields({
                            [field]: { value, errors },
                        })
                    },
                    null,
                    null
                ),
            },
        ],
    })(
        <Select
            showSearch
            style={{ width: 70 }}
            dropdownStyle={{ width: 260 }}
            optionLabelProp="title"
            optionFilterProp="children"
            dropdownMatchSelectWidth={false}
            dropdownClassName="phone-input-dropdown"
            getPopupContainer={trigger => trigger.parentNode}
            filterOption={(input, option) => option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) === 0}
        >
            {countryCodes.map(code => (
                <Select.Option key={code.code} title={code.dial_code}>
                    {code.name} ({code.dial_code})
                </Select.Option>
            ))}
        </Select>
    )
    return (
        <ContentWrapper>
            <h1 className="header-text">Sign Up</h1>
            <div className="already-have-an-acco">
                Already have an account?
                <button className="have-account" onClick={() => history.push('/signin/')}>
                    Log In
                </button>
            </div>
            <FormWrapper>
                <Form className="signup-form">
                    <Form.Item>
                        {getFieldDecorator('fullName', {
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Full Name is required',
                                },
                            ],
                            normalize: (value, prev) => {
                                let isValid = /^[a-zA-Z_ ]+$/.test(value)
                                if (value === undefined || value === '') return value
                                if (!isValid) return prev
                                return value
                            },
                        })(
                            <Input
                                className="background-colour"
                                placeholder="Enter Your Full Name"
                                suffix={<Icon type="user" className="signup-user-icon" />}
                                onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('email', {
                            validateTrigger: 'onBlur',
                            initialValue: slug && slug.email,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Email is required',
                                },
                                { type: 'email', message: 'Invalid Email!' },
                                {
                                    validator: emailValidator(
                                        field => form.getFieldValue(field),
                                        (field, value, errors) => {
                                            form.setFields({
                                                [field]: { value, errors },
                                            })
                                        },
                                        null,
                                        null
                                    ),
                                },
                            ],
                        })(
                            <Input
                                className="background-colour"
                                placeholder="Enter Your Email"
                                suffix={<Icon component={EmailIcon} />}
                                disabled={!!slug}
                                onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('phone', {
                            validateTrigger: 'onBlur',
                            initialValue: slug && slug.phone,
                            rules: [
                                {
                                    validator: phoneValidator(
                                        field => form.getFieldValue(field),
                                        (field, value, errors) => {
                                            form.setFields({
                                                [field]: { value, errors },
                                            })
                                        },
                                        null,
                                        null
                                    ),
                                },
                            ],
                        })(
                            <Input
                                className="background-colour"
                                addonBefore={prefixSelector}
                                placeholder="Enter Your Phone Number"
                                suffix={<PhoneLogo />}
                                // onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('companyName', {
                            initialValue: slug && slug.companyName,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Company Name is required',
                                },
                                {
                                    message: 'Please enter valid Company Name',
                                },
                            ],
                        })(
                            <Input
                                className="background-colour"
                                placeholder="Enter Your Company Name"
                                suffix={<Icon component={CompanyNameIcon} />}
                                disabled={!!slug}
                                onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('websiteUrl', {
                            validateTrigger: 'onBlur',
                            initialValue: slug && slug.websiteUrl,
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Website URL is required',
                                },
                            ],
                        })(
                            <Input
                                className="background-colour"
                                placeholder="Enter Your Website URL"
                                disabled={!!slug}
                                suffix={<Icon component={URLIcon} />}
                                onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            validateTrigger: 'onBlur',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: 'Password is required',
                                },
                                {
                                    min: 6,
                                    message: 'Password must contain atleast 6 characters',
                                },
                                {
                                    pattern: /\d/,
                                    message: 'Password must contain a number',
                                },
                            ],
                        })(
                            <Input
                                className="background-colour"
                                placeholder="Enter Your Password"
                                suffix={
                                    <Icon component={PasswordIcon} onClick={() => setPasswordType(!passwordType)} />
                                }
                                type={passwordType ? 'password' : 'text'}
                                onPressEnter={handleEnterPress}
                            />
                        )}
                    </Form.Item>
                    <div>
                        Do you need an Assessment Provider?
                        <Form.Item>
                            {getFieldDecorator('isAssessmentRequired', {
                                initialValue: 0,
                            })(
                                <RadioGroup>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                </RadioGroup>
                            )}
                        </Form.Item>
                    </div>
                    <div>
                        Do you want to run Employee Background Verification?
                        <Form.Item>
                            {getFieldDecorator('isBgvRequired', {
                                initialValue: 0,
                            })(
                                <RadioGroup>
                                    <Radio value={1}>Yes</Radio>
                                    <Radio value={0}>No</Radio>
                                </RadioGroup>
                            )}
                        </Form.Item>
                    </div>
                </Form>
            </FormWrapper>
            <Button className="signup-button" size="large" onClick={signup} loading={loading}>
                Sign Up
            </Button>
            <ToCWrapper>
                By signing up, you agree to our <br />
                <a style={{ color: '#4767A0' }} href="https://www.unberry.com/privacy-policy" target="_blank">
                    Terms & Conditions
                </a>{' '}
                and{' '}
                <a style={{ color: '#4767A0' }} href="https://www.unberry.com/privacy-policy" target="_blank">
                    Privacy Policy
                </a>
            </ToCWrapper>
        </ContentWrapper>
    )
}
