import styled, { createGlobalStyle } from 'styled-components'
import Bullet from '../../../assets/Icons/Landing/bullet.svg'
import Quote from '../../../assets/Icons/Landing/quote.svg'

export const GlobalStyle = createGlobalStyle`
    html body {
        background: #fff;
    }
    .desktop-navbar, footer.ant-layout-footer {
        display: none;
    }
    .layout .overall-content {
        margin-top: 0;
    }
	* {
		font-family: Montserrat-Medium;
	}
	p {
		font-size: 18px;
		line-height: 22px;
		color: #718096;
		font-family: Montserrat;
	}
    button + a {
        margin-left: 20px;
    }
    input:focus, input:active{
        outline-color: #0e63f4;
    }
    .show-sm {
        display: none;
    }
    @media (min-width: 767px) {
        .center {
            text-align: center;
        }
    }
    @media (max-width: 767px) {
        .layout .mobile-menu {
            display: none;
        }
        p {
            font-size: 14px;
            line-height: 17px;
        }
        button + a {
            margin-left: 0;
        }
        .hide-sm {
            display: none;
        }
        .show-sm {
            display: block;
        }
        .buttons {
            display: flex;
            align-items: center;
            flex-direction: column;
            > button {
                order: 2;
                + a {
                    margin-bottom: 15px;
                }
            }
        }
    }
`
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    height: 80px;
    padding: 0 calc((100% - 1232px) / 2);
    margin: auto;
    position: sticky;
    top: 0;
    align-items: center;
    background: ${props => (props.open ? '#fff' : '#0e63f4')};
    z-index: 2;
    a {
        margin-right: 35px;
        color: #fff;
    }
    @media (min-width: 767px) {
        .hamburger {
            display: none;
        }
    }
    @media (max-width: 1300px) {
        padding: 0 5%;
    }
    @media (max-width: 767px) {
        padding: 0 calc(20% / 2);
        height: 60px;
        > a {
            line-height: 0;
        }
        .hamburger {
            width: 16px;
            height: 14px;
            position: relative;
            margin-left: auto;
            transform: rotate(0deg);
            transition: 0.5s ease-in-out;
            cursor: pointer;

            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: ${props => (props.open ? '#0e63f4' : '#fff')};
                border-radius: 9px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;
            }
            span:nth-child(1) {
                top: 0px;
            }

            span:nth-child(2),
            span:nth-child(3) {
                top: 5px;
            }

            span:nth-child(4) {
                top: 10px;
            }

            &.open span:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }

            &.open span:nth-child(2) {
                transform: rotate(45deg);
            }

            &.open span:nth-child(3) {
                transform: rotate(-45deg);
            }

            &.open span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
        svg {
            width: 74px;
            height: 26px;
        }
        .links {
            display: none;
        }
    }
`
export const Menu = styled.ul`
    font-size: 18px;
    line-height: 22px;
    color: #4a5568;
    padding-left: 35px;
    list-style: none;
    margin-top: 20px;
    li {
        font-family: Montserrat;
        + li {
            margin-top: 34px;
        }
    }
    @media (max-width: 767px) {
        a {
            color: #718096;
        }
    }
`
export const Main = styled.main`
    padding-top: 113px;
    @media (max-width: 767px) {
        padding-top: 40px;
    }
`
export const BannerSection = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    grid-gap: 80px;
    width: 1133px;
    margin: auto;
    .content {
        margin: auto 0;
        h1 {
            margin-bottom: 30px;
        }
        .buttons {
            margin-top: 40px;
        }
    }
    @media (max-width: 1200px) {
        width: 80%;
        svg {
            width: 200px;
            height: 200px;
            margin: auto;
        }
        .content {
            .buttons {
                display: flex;
                align-items: center;
                margin-top: 32px;
            }
            h1 {
                margin-bottom: 16px;
                padding: 0;
            }
            p {
                margin-bottom: 32px;
            }
        }
        img {
            min-width: 200px;
            min-height: 200px;
            width: 40vw;
            height: 40vh;
            margin: auto;
        }
    }
`
export const Companies = styled.div`
    .slick-slide > div > div {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        height: 150px;
    }
    margin: 60px auto 0;
    max-width: 1133px;
    @media (max-width: 1200px) {
        width: 100%;
        .slick-slide > div > div {
            flex-wrap: wrap;
        }
        img {
            max-width: 50%;
            max-height: 60px;
            padding: 0 20px;
        }
        svg {
            height: 30px;
            width: 50%;
        }
        .apollo {
            height: 50px;
        }
        .cred {
            height: 40px;
        }
    }
`
export const H1 = styled.h1`
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #4a5568;
    ${props =>
        props.underlined
            ? `
            display: inline-block;
            border-bottom: 3px solid #9CE37D;
            padding: 10px;
            margin-bottom: 20px;`
            : undefined}
    b {
        color: #0e63f4;
        font-family: Montserrat-Bold;
    }
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        padding: 10px 0;
    }
`
export const H2 = styled.h2`
    font-weight: 600;
    font-size: 24px;
    line-height: 38px;
    color: #4a5568;
    ${props =>
        props.underlined
            ? `
            display: inline-block;
            border-bottom: 3px solid #9CE37D;
            padding-bottom: 5px;
            margin-bottom: 20px;`
            : undefined}
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
    }
`
export const H3 = styled.h3`
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #4a5568;
    @media (max-width: 767px) {
        font-size: 18px;
        line-height: 22px;
    }
`
export const H4 = styled.h4`
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #4a5568;
    margin-bottom: 20px;
    :after {
        content: '';
        display: block;
        margin-top: 10px;
        width: 60px;
        height: 3px;
        background: #9ce37d;
        border-radius: 100px;
    }
`
export const Description = styled.p`
    font-size: 24px;
    line-height: 29px;
    color: #718096;
    font-family: Montserrat;
    @media (max-width: 767px) {
        font-size: 14px;
        line-height: 17px;
        width: 90%;
    }
`
export const Button = styled.button`
    background: ${props => (props.secondary ? '#fff' : props.top ? '#EDF6FF' : '#0e63f4')};
    color: ${props => (props.secondary ? '#0e63f4' : props.top ? '#0E63F4;' : '#fff')};
    border: ${props => (props.secondary ? '1px solid #0E63F4' : 'none')};
    padding: ${props => (props.secondary ? '18px 20px' : '18px 40px')};
    border-radius: 10px;
    font-family: Montserrat-Bold;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    outline: none;
    transition: box-shadow 0.3s ease, opacity 0.3s ease;
    :active {
        transform: translateY(2px);
    }
    :hover {
        ${props => (props.secondary || props.top ? ` box-shadow: 0 0 0 2px #0e63f4; ` : ` opacity: 0.8; `)}
    }
    @media (max-width: 767px) {
        width: 182px;
        padding: 18px 0;
    }
`
export const SubTitle = styled.span`
    font-size: 24px;
    line-height: 29px;
    color: #718096;
`
export const Features = styled.div`
    background: #e5eeff;
    clip-path: polygon(0 1%, 74% 5%, 100% 0, 100% 100%, 25% 95%, 0 100%);
    > div {
        background: #f5f7fb;
        padding: 200px calc((100% - 1133px) / 2);
        clip-path: polygon(0 1%, 74% 7%, 108% 0, 100% 100%, 25% 93%, 0 100%);
    }
    @media (max-width: 767px) {
        clip-path: polygon(0 1%, 74% 1%, 100% 0, 100% 100%, 25% 99%, 0 100%);
        > div {
            clip-path: polygon(0 1%, 74% 2%, 108% 0, 100% 100%, 25% 98%, 0 100%);
            padding: 50px 10%;
        }
    }
    @media (max-width: 1200px) {
        > div {
            padding: 150px 5%;
        }
    }
`
export const Feature = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    grid-gap: 100px;
    margin: 150px 0;

    p {
        margin-bottom: 40px;
    }

    img {
        width: 100%;
    }

    video {
        width: 100%;
    }

    .drop-resume {
        font-size: 14px;
        font-weight: bold;
        color: #a0aec0;
        position: relative;

        > svg {
            position: absolute;
            top: -75px;
            right: 50px;
            z-index: 1;
        }
        .drop-area {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #f9fcfd;
            border: 2px dashed ${({ uploadError }) => (uploadError ? 'red' : '#f9c784')};
            box-sizing: border-box;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
            position: relative;
            cursor: pointer;
            border-radius: 4px;
            margin-bottom: 8px;
            height: 171px;
            width: 100%;
            :focus {
                outline: none;
            }
            :hover {
                outline: #0e63f4 auto 2px;
            }
            svg {
                margin-bottom: 15px;
            }
            .cancel {
                all: unset;
                position: absolute;
                top: 15px;
                right: 15px;
                width: 16px;
                height: 16px;
                margin-bottom: 0;
                g {
                    fill: #0e63f4;
                }
            }
        }
        .form {
            display: grid;
            grid-template-columns: 49.5% 49.5%;
            grid-gap: 5px;
            div {
                display: flex;
                align-items: center;
                background: #fff;
                height: 42px;
                border: none;
                padding: 0 10px;
                font-weight: bold;
                &::placeholder {
                    color: #a0aec0;
                }
                span {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        margin: 80px 0;
        > div:first-child {
            order: 2;
        }
        p {
            margin: 50px 0 30px;
        }
        .drop-resume {
            font-size: 12px;
            .drop-area {
                margin-top: 80px;
            }
            > svg {
                top: 70px;
                left: 50%;
                width: 110px;
            }
        }
    }
`
export const StepsSection = styled.div`
    width: 80%;
    margin: auto;
    margin-top: 70px;
`
export const StepsBody = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    grid-gap: 0 10%;
    max-width: 950px;
    margin: auto;
    margin-top: 117px;
    .buttons {
        display: flex;
        justify-content: center;
        grid-column-start: 1;
        grid-column-end: 3;
        margin-bottom: 100px;
    }
    @media (max-width: 1000px) {
        width: 100%;
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin-top: 50px;
        .buttons {
            align-items: center;
        }
        .hide-sm {
            display: none;
        }
    }
`
export const Steps = styled.div`
    position: relative;
    :before {
        content: '';
        display: block;
        height: 580px;
        width: 1px;
        position: absolute;
        background: #9ce37d;
        left: 44px;
    }
    .step {
        display: flex;
        margin-bottom: 87px;
        :last-child {
            margin-bottom: 60px;
        }
        svg {
            flex-shrink: 0;
            margin-right: 25px;
            z-index: 1;
        }
        h3 {
            font-size: 18px;
            margin-bottom: 2px;
        }
        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
    @media (max-width: 767px) {
        .step {
            margin-bottom: 60px;
            h3 {
                font-size: 14px;
            }
            svg {
                width: 55px;
                height: 55px;
            }
        }
        :before {
            height: 420px;
            left: 28px;
        }
    }
`
export const GoMobile = styled.div`
    background: #e5eeff;
    clip-path: polygon(0 1%, 74% 9%, 100% 0, 100% 100%, 32% 92%, 0 100%);
    > div {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-gap: 110px;
        padding: 200px calc((100% - 1133px) / 2);
        background: #f5f7fb;
        clip-path: polygon(0 1%, 74% 17%, 100% 0, 100% 100%, 31% 86%, 0 100%);
        ul {
            margin-bottom: 40px;
            padding-left: 0;
            list-style: none;
            li {
                display: flex;
                font-size: 18px;
                line-height: 22px;
                font-family: Montserrat;
                color: #718096;
                margin-bottom: 20px;
                ::before {
                    content: url(${Bullet});
                    margin-right: 10px;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        clip-path: polygon(0 1%, 74% 5%, 100% 0, 100% 100%, 32% 96%, 0 100%);
        img {
            width: 100%;
            margin: auto;
        }
        > div {
            clip-path: polygon(0 1%, 74% 9%, 100% 0, 100% 100%, 31% 93%, 0 100%);
            padding: 100px 10%;
            grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
            grid-gap: 50px;
            ul {
                li {
                    font-size: 14px;
                    line-height: 17px;
                    position: relative;
                    :after {
                        content: '';
                        display: block;
                        width: 20px;
                        height: 3px;
                        background: #9ce37d;
                        border-radius: 100px;
                        position: absolute;
                        top: -8px;
                    }
                    :before {
                        content: none;
                    }
                    + li {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
`
export const Achievements = styled.div`
    max-width: 1133px;
    margin: 100px auto;
    @media (max-width: 1000px) {
        width: 100%;
        margin: 50px auto;
        text-align: center;
        h2 {
            font-size: 18px;
            line-height: 22px;
        }
    }
`
export const Stats = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 100px;
    h2 {
        color: #666;
        font-family: Montserrat-Bold;
    }
    @media (max-width: 1000px) {
        flex-wrap: wrap;
        width: 80%;
        margin: 20px auto 50px;
        svg {
            width: 20px;
            height: 20px;
        }
    }
`
export const Stat = styled.div`
    > div {
        display: flex;
        align-items: center;
    }
    h2 {
        margin-bottom: 0;
        font-weight: bold;
    }
    svg {
        margin-right: 10px;
    }
    p {
        margin-bottom: 0;
    }
    @media (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        text-align: center;
        padding: 10px 0;
        p {
            font-size: 10.5px;
            line-height: 13px;
            margin-top: 5px;
        }
        svg {
            margin-right: 5px;
        }
    }
`
export const Testimonials = styled.div`
    display: flex;
    flex-direction: column;
    background: #f5f7fb;
    align-items: center;
    padding-top: 49px;
    .card {
        width: 785px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);

        border-radius: 10px;
        margin: 45px 0 99px;
        position: relative;
        .user {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin-bottom: 30px;
            > svg,
            > img {
                width: 68px;
                height: 68px;
                margin-right: 20px;
                flex-shrink: 0;
                border-radius: 50%;
            }
        }
        h3 {
            font-weight: bold;
            font-size: 18px;
            color: #4a5568;
            margin-bottom: 0;
        }
        .role {
            font-size: 14px;
            line-height: 17px;
            font-weight: 200;
            color: #9e9e9e;
            font-family: Montserrat;
        }
        p {
            font-style: italic;
            font-size: 18px;
            line-height: 22px;
            color: #718096;
            font-weight: 400;
            font-family: Montserrat;
            margin: 48px 99px 59px;
            &:before {
                content: url(${Quote});
                position: absolute;
                left: 44px;
                top: 38px;
            }
        }
        .arrows {
            position: absolute;
            bottom: -60px;
            left: 50%;
            transform: translateX(-50%);
            svg {
                cursor: pointer;
                + svg {
                    margin-left: 20px;
                }
                &:nth-child(2) {
                    transform: rotate(180deg);
                }
            }
        }
    }
    @media (max-width: 767px) {
        margin-top: 65px;
        width: 90%;
        margin: auto;
        background: #fff;
        padding: 0;
        .quote {
            margin: 0 auto 30px;
        }
        .card {
            width: 80%;
            margin: auto;
            border: none;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
            .user {
                > svg,
                > img {
                    width: 56px;
                    height: 56px;
                }
                padding: 0 19px;
            }
            p {
                margin: 58px 19px 45px;
                &:before {
                    top: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        .button {
            margin: 40px auto 0;
        }
    }
`
export const WhyUs = styled.div`
    .card {
        margin: 100px auto;
        width: 80%;
        min-height: 441px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 160px;
        background: #f5f7fb;
        border-radius: 0px 100px;
        h2 {
            color: #0e63f4;
            margin-bottom: 30px;
            font-family: Montserrat;
        }
    }
    .buttons {
        display: flex;
        margin-top: 20px;
    }
    @media (max-width: 767px) {
        .card {
            margin: 110px auto 0;
            border-radius: 0px 50px;
            padding: 48px 30px;
            h2 {
                font-weight: 600;
                text-align: center;
            }
        }
    }
`
export const Footer = styled.div`
    margin-top: 124px;
    padding: 65px calc((100% - 1133px) / 2) 25px;
    background: #0e63f4;
    .links {
        display: flex;
        justify-content: space-between;
    }
    h2 {
        color: #fff;
        font-size: 20px;
    }
    button {
        background: #fff;
        color: #0e63f4;
        border-radius: 5px;
        padding: 12px 18px;
    }
    input {
        width: 209px;
        height: 39px;
        background: #ffffff;
        border: 1px solid #cccccc;
        margin-right: 10px;
        border-radius: 4px;
        font-size: 14px;
        padding: 0 15px;
        ::placeholder {
            color: #cbd5e0;
        }
    }
    p {
        color: #a7cbff;
        margin-top: 16px;
        font-size: 14px;
        font-family: Montserrat-Medium;
    }
    ul {
        list-style: none;
        padding: 0;
        li {
            color: #fff;
            margin-bottom: 10px;
            a {
                font-size: 14px;
                line-height: 22px;
                color: #a7cbff;
            }
            &.title {
                font-size: 16px;
                font-weight: bold;
            }
            + .title {
                margin-top: 60px;
            }
        }
    }
    .bottom {
        display: flex;
        justify-content: flex-end;
        @media (max-width: 767px) {
            justify-content: flex-start;
        }
        margin-top: 50px;
        form {
            width: 50%;
        }
        svg {
            position: relative;
            right: 77px;
            @media (max-width: 767px) {
                right: unset;
            }
        }
        
    }
    .copyright {
        margin-top: 30px;
        svg + svg {
            margin-left: 20px;
        }
    }
    @media (max-width: 1200px) {
        padding: 65px 5% 25px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        padding-top: 44px;
        margin-top: 50px;
        h2 {
            font-size: 14px;
        }
        form {
            order: 2;
            padding-left: 35px;
            input {
                width: 183px;
                height: 32px;
            }
            button {
                width: 101px;
                height: 32px;
                padding: 0;
                font-size: 12px;
            }
        }
        p {
            width: 90%;
        }
        .links {
            flex-direction: column;
            ul {
                margin-bottom: 35px;
            }
        }
        .copyright {
            margin-top: 30px;
        }
    }
`
