import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Content, Header } from './style'
import { Wrapper } from './style'
import { Loader, Success, InfoBox, Uploader, ErrorsPreview } from './Components'
import { FooterBar } from '../../App/style'
import { warning } from '../../../services/Notification'
import { ImportCSVFile, GetImportStatus } from '../../../services/NetworkCalls/Candidate'

class AddCandidateSpreadsheet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leftStep: 1,
            rightStep: 1,
            successfulUploads: null,
            failedUploads: null,
            errorFilesLink: null,
            disabled: false,
        }
        this.changeStep = this.changeStep.bind(this)
        this.timer = null
        this.isInProgress = false
    }

    componentDidMount() {
        this.checkStatus()
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        this.timer = null
    }

    checkStatus = async () => {
        const res = await this.props.GetImportStatus()

        if (res.data.status === 'in_progress') {
            this.changeStep(1, 2)
            this.timer = setInterval(this.getUploadStatus, 800)
        }
    }

    changeStep = (leftStep = this.state.leftStep, rightStep = this.state.rightStep) => {
        if (rightStep === 2) this.setState({ leftStep, rightStep, disabled: true })
        else this.setState({ leftStep, rightStep, disabled: false })
    }

    setDisable = value => this.setState({ disabled: value })

    getUploadStatus = async () => {
        if (this.isInProgress === false) {
            this.isInProgress = true
            const res = await this.props.GetImportStatus()
            this.isInProgress = false
            if (res && (res.error || (res.data && res.data.status === 'failed'))) {
                warning((res.error && res.error.msg) || 'Upload failed')
                clearInterval(this.timer)
                this.timer = null
            } else if (res && res.data && res.data.status === 'completed') {
                const { success, errors } = res.data.metadata
                const successfulUploads = success.createdCount
                const failedUploads = errors.count
                const errorFilesLink = errors.link
                clearInterval(this.timer)
                this.timer = null
                this.changeStep(2, 3)
                this.setState({ successfulUploads, failedUploads, errorFilesLink })
            }
        }
    }

    onSubmit = async ({ excelFileError, excelUploadStatus, selectedJob, selectedStage }) => {
        if (!excelFileError && excelUploadStatus === 'uploaded') {
            this.changeStep(1, 2)
            const res = await this.props.ImportCSVFile({ jobId: selectedJob, stageId: selectedStage })

            if (res.error) warning(res.error.msg)
            else if (this.timer === null) this.timer = setInterval(this.getUploadStatus, 800)
        }
    }

    render() {
        const { leftStep, rightStep, successfulUploads, failedUploads, errorFilesLink, disabled } = this.state
        return (
            <>
                <Wrapper>
                    <Header>
                        <div className={`breadcrumb ${disabled && 'disabled'}`}>
                            <span onClick={() => !disabled && this.props.history.push('/talent-pool')}>
                                Talent Pool
                            </span>{' '}
                            / Import candidate through spreadsheet
                        </div>
                        <div className="title">IMPORT CANDIDATE THROUGH SPREADSHEET</div>
                    </Header>
                    <Content>
                        <div>
                            {leftStep === 1 && <InfoBox />}
                            {leftStep === 2 && <Success />}
                        </div>
                        <div>
                            {rightStep === 1 && <Uploader setDisable={this.setDisable} onSubmit={this.onSubmit} />}
                            {rightStep === 2 && <Loader />}
                            {rightStep === 3 && (
                                <ErrorsPreview
                                    success={successfulUploads}
                                    fails={failedUploads}
                                    link={errorFilesLink}
                                    history={this.props.history}
                                    changeStep={this.changeStep}
                                />
                            )}
                        </div>
                    </Content>
                </Wrapper>
                <FooterBar className="todo-footer">
                    <a href=" https://springrecruit.com">&copy; SpringRecruit {new Date().getFullYear()}</a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.unberry.com/privacy-policy"
                    >
                        Terms &amp; conditions
                    </a>
                    <a target="_blank" href="https://www.unberry.com/privacy-policy" rel="noopener noreferrer">
                        Privacy policy
                    </a>
                </FooterBar>
            </>
        )
    }
}

export default connect(
    null,
    { ImportCSVFile, GetImportStatus }
)(AddCandidateSpreadsheet)
