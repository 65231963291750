import React from 'react'
import { FooterBar } from './style'

export const ProjectFooter = ({ location }) => {
    const isATSPage = location && location.pathname.indexOf('openings/board') > 0
    const isCustomise = location && location.pathname.indexOf('/careers/customise') === 0

    if (isATSPage || isCustomise) return null
    return (
        <FooterBar className="todo-footer">
            <a href=" https://springrecruit.com">&copy; SpringRecruit {new Date().getFullYear()}</a>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.unberry.com/privacy-policy"
            >
                Terms &amp; conditions
            </a>
            <a target="_blank" href="https://www.unberry.com/privacy-policy" rel="noopener noreferrer">
                Privacy policy
            </a>
        </FooterBar>
    )
}
